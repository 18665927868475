
import { Instructors } from "@/api";
import { Gender, Instructor, Service } from "@/api/types";
import Card from "@/components/common/Card.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import InstructorCoursesList from "@/components/courses/InstructorCoursesList.vue";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { InstructorCoursesList, PageTitle, Card }
})
export default class ViewInstructorsPage extends Vue {
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Edit",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "edit"
    }
  ];
  headersTitle: Array<{ icon: string; header: string }> = [
    { icon: "mdi-account-box", header: "John Doe" }
  ];
  instructor: Instructor = {
    id: null,
    name: "",
    email: "",
    phone: "",
    genderId: null,
    workingHourFrom: "",
    workingHourTo: "",
    serviceId: null,
    outsourceCost: "",
    vacantionFrom: "",
    vacantionTo: ""
  };
  servicesAndGenders: { genders: Array<Gender>; services: Array<Service> } = {
    services: [],
    genders: []
  };
  activeTab = null;
  tabs = ["Personal details", "Assigned courses"];
  get instructorId() {
    return this.$route.params.id;
  }
  get gender() {
    return this.servicesAndGenders.genders.find(
      (item: Gender) => item.id === this.instructor.genderId
    )?.name;
  }
  get service() {
    return this.servicesAndGenders.services.find(
      (item: Service) => item.id === this.instructor.serviceId
    )?.name;
  }
  get keyDetails() {
    return [
      { title: "Name", content: this.instructor.name },
      { title: "Email address", content: this.instructor.email },
      { title: "Phone number(s)", content: this.instructor.phone },
      { title: "Gender", content: this.gender }
    ];
  }
  get other() {
    const vacationFromArray = this.instructor.vacantionFrom?.split("-");
    const vacationToArray = this.instructor.vacantionTo?.split("-");
    let vacationFrom = new Date().toString();
    let vacationTo = new Date().toString();
    if (vacationFromArray) {
      vacationFrom = `${vacationFromArray[1]}-${vacationFromArray[0]}-${vacationFromArray[2]}`;
    }
    if (vacationToArray) {
      vacationTo = `${vacationToArray[1]}-${vacationToArray[0]}-${vacationToArray[2]}`;
    }
    return [
      { title: "Outsource cost", content: this.instructor.outsourceCost },
      { title: "Service", content: this.service },
      {
        title: "Leave from",
        content: moment(vacationFrom).format("DD/MM/YYYY")
      },
      {
        title: "Leave to",
        content: moment(vacationTo).format("DD/MM/YYYY")
      },
      { title: "Working hour from", content: this.instructor.workingHourFrom },
      { title: "Working hour to", content: this.instructor.workingHourTo }
    ];
  }
  async created() {
    try {
      [this.instructor, this.servicesAndGenders] = await Promise.all([
        Instructors.info(this.instructorId),
        Instructors.servicesAdnGenders()
      ]);
      this.headersTitle[0].header = this.instructor.name || "";
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onGoBack() {
    await this.$router.push({ name: "Instructors" });
  }

  async onGoEdit() {
    await this.$router.push({
      name: "EditInstructor",
      params: { id: this.instructorId }
    });
  }
}
