
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Card extends Vue {
  @Prop({ default: "" })
  cardTitle!: string;

  @Prop({ default: "" })
  diagram!: string;

  @Prop({ default: () => [] })
  cardContent!: any;
}
